.tiles {
	display: flex;
	align-self: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	@include bp(mini) {
		display: block;		
	}

	.tile {
		background-color: #fff;
		text-align: center;
		box-shadow: 0 0 5px rgba(0,0,0,0.3);
		border-radius: 3px;
		flex-basis: calc(100% / 3 - 20px);
		margin: 0 10px 20px;
		overflow: hidden;
		@include bp(sm) {
			flex-basis: calc(50% - 20px);
		}

		&:hover {
			box-shadow: 0 3px 20px rgba(0,0,0,0.3);
		}

		div {
			&.image {
				img {
					display: block;
					margin-bottom: 0;
				}
			}

			&.content {
				padding: 20px;
			}
		}
	}
}