main > header {
	font-weight: 700;
	font-size: 0.9rem;
	padding: 20px;
	display: flex;
	position: relative;
	background-color: #fff;
	border-bottom: 1px solid rgba($gray, 0.5);

	.logo {
		transform: translateY(-3px);

		a {
			display: block;
			width: 125px;
			height: 60px;
			text-indent: -9999px;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url('/images/logo_lg.svg');
			@include bp(mobile) {
				width: 24px;
				height: 38px;
				background-image: url('/images/logo_sm.svg');
				transform: scale(1.2);
			}
		}
	}

	ul {
		display: flex;
		list-style: none;
		margin: 0;
		position: absolute;
		right: 20px;

		li {
			margin-right: 20px;
			line-height: 60px;
			@include bp(mobile) {
				line-height: 38px;
			}
			@include bp(mini) {
				margin-right: 10px;
				font-size: 0.8rem;
			}

			&:not(.sign-in) {
				@include bp(mobile) {
					display: none;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}