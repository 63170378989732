.dropdown {
	> ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0;
		margin: 0;
		@include bp(mobile) {
			display: block;
		}

		li.dropdown-item {
			flex-basis: calc(50% - 40px);
			list-style-type: none;
			border-bottom: 1px solid $gray;
			margin: 0;
			padding: 30px 0;

			&:last-child {
				border-bottom: none;
			}

			&:nth-last-child(2) {
				@include bp(lrg) {
					border-bottom: none;
				}
			}

			.content {
				&.active {
					h4 {
						background-image: url('/images/caret-up.svg');
					}

					.dropdown-content {
						display: block;
					}
				}

				h4 {
					margin: 0;
					font-weight: bold;
					position: relative;
					cursor: pointer;
					background-image: url('/images/caret-down.svg');
					background-repeat: no-repeat;
					background-position: right center;
					background-size: 15px;
					padding-right: 30px;
				}

				.dropdown-content {
					display: none;
					padding-right: 30px;
					margin-top: 0.5rem;

					p, ul {
						font-size: 15px;

						li {
							list-style-type: disc;
							margin-bottom: 0.5rem;
						}
					}
				}
			}
		}
	}
}