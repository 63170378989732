form {
	select {
		width: 100%;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: $lightGray url('/images/caret-down.svg') no-repeat;
		background-position: right 20px center;
		background-size: 10px;
		cursor: pointer;
		border: 2px solid $gray;
		font-size: 16px;
		padding: 8px 45px 9px 15px;
		margin-bottom: 20px;
		height: 45px;
		border-radius: 100px;
		text-indent: 8px;
		outline: none;

		&:focus {
			border-color: $primary;
		}
	}
}